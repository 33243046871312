<template>
    <div>
        <div class="fixed inset-0 bg-red-200" style="opacity: 0.5; z-index: 99;" aria-hidden="true" ></div>
            <!--   delete modal   -->
        <div class="inset-0 fixed flex justify-center items-center" style="z-index: 99;">
          <div class=" flex flex-col w-16/12 sm:w-5/6 lg:w-1/2 max-w-md mx-auto rounded-lg border border-gray-300 shadow-xl">
            <div class=" flex flex-row justify-between items-center px-3 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
              <p class="font-semibold text-sm text-gray-800">Annuler un abonnement</p>
              <svg @click="close()" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </div>
            <div class="flex flex-col px-6 py-5 bg-gray-50">
              <div class=" flex flex-col sm:flex-row items-center text-center text-sm p-3 sm:space-x-5">   
                <div class="w-full">
                  Voulez vous vraiment annuler cette abonnement ?
                </div>
              </div>
            </div>
            <div
              class=" flex flex-row items-center justify-between px-6 py-3 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg "
            >
              <button
                @click="close()"
                class="font-semibold text-gray-600 text-sm"
              >
                Annuler
              </button>
              <button @click="confirm()" class=" text-sm px-4 text-red-400 font-semibold bg-white rounded"
              >
                Confirmer
              </button>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    setup (_,{emit}) {
        

        function confirm() {
            emit('confirm')
        }
        function close() {
            emit('close');
        }
        return {
            confirm,
            close
        }
    }
}
</script>

<style lang="scss" scoped>

</style>