<template>
    <el-dialog v-model="UpdateDialogFormVisible" title="Modifer les informations de l'utilisateur" width="500">
        <el-form :model="form" :rules="rules" ref="ruleFormRef">
            <!--<el-form-item label="Promotion name">
                <el-input v-model="form.name" autocomplete="off" />
            </el-form-item>-->
            <el-form-item label="Roles" prop="selectedRole">
                <el-select v-model="form.selectedRole" placeholder="Please select a role">
                    <el-option v-for="(item, index) in roles" :label="item" :value="item" :key="index" />
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="close()">Cancel</el-button>
                <el-button type="primary" @click="editUser()" :disabled="isLoading">
                    Confirm
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import { reactive, ref } from 'vue';
import UserRequests from '../../services/requests/user';
export default {
    props: {
        UpdateDialogFormVisible: Boolean,
        userId: String,
        user_data: Object
    },
    setup(props, { emit }) {
        const ruleFormRef = ref();
        const isLoading = ref(false);
        const roles = ref([
            'superadmin',
            'admin',
            'etudiant'
        ]);

        const form = reactive({
            selectedRole: props.user_data.role
        })

        const rules = reactive({
            selectedRole: [
                { required: true, message: 'Le rôle est requis.', trigger: 'blur' },
            ],
        });

        /*function send() {
            if (form.value.selectedRole !== '') {
                emit('send', form)
            }
        }*/

        function close() {
            emit('close');
        }

        async function editUser() {
            ruleFormRef.value.validate(async (valid) => {
                if (valid) {
                    isLoading.value = true;
                    try {
                        let id = props.user_data.id;
                        const response = await UserRequests.editUser(id, form);
                        if (response) {
                            emit('refresh');
                            emit('close');
                        }
                    } catch (error) {

                    } finally {
                        isLoading.value = false;
                    }
                }
            })

        }
        return {
            roles,
            form,
            close,
            editUser,
            isLoading,
            rules,
            ruleFormRef
        }
    }
}
</script>

<style lang="scss" scoped></style>