import axios from "@/plugins/axios";

const UserRequests = {
    async getUserDetails(id) {
        try {
            const response = await axios.get(`/getUserDetails/${id}`);
            return response;
        } catch (error) {
            throw error;
        }
    },

    async editUser(id, form) {
        try {
            const response = await axios.post(`/editUser/${id}`, form);
            return response;
        } catch (error) {
            throw error;
        }
    }
};

export default UserRequests;