<template>
    <div class="student-details p-4 w-full">
        <div class="wrapper">
            <el-alert :title="alertTitle" :type="alertType" show-icon @close="isAlert = false" v-if="isAlert" />
            <div class="headline bg-white border border-gray p-4 rounded-md flex justify-between items-start">
                <div class="flex items-center gap-3">
                    <div class="user-info--left w-28 h-28">
                        <img :src="userDetails.profil_image != null ? userDetails.profil_image : '/avatar_profile.jpg'" alt="" class="w-100  rounded-full">
                    </div>
                    <div class="user-info--right flex flex-col gap-3">
                        <p class="font-medium text-gray-500">{{ userDetails.lastname }} {{ userDetails.firstname }}</p>
                        <!--<p class="font-medium text-gray-500">test@email.com</p>-->
                        <p class="font-medium text-gray-500">{{ userDetails.role }}</p>
                    </div>
                </div>
                <button class="primary-btn gray-btn flex p-2 gap-2 items-center sm:w-auto w-full justify-center"
                    @click="showUpdateUserModal = true">
                    <EditIcon />
                    <span>Modifier</span>
                </button>
            </div>
            <div class="personal-information p-4 bg-white rounded-md border border-gray">
                <div class="personal-information__title mb-3">
                    <h3 class="text-lg font-medium">Informations</h3>
                </div>
                <div class="personal-information__content">
                    <div class="personal-information__content_item">
                        <p class="text-gray-500 font-normal mb-2">Nom</p>
                        <p class="text-gray-700 font-medium">{{ userDetails.lastname }}</p>
                    </div>
                    <div class="personal-information__content_item">
                        <p class="text-gray-500 font-normal mb-2">Prenom</p>
                        <p class="text-gray-700 font-medium">{{ userDetails.firstname }}</p>
                    </div>
                    <div class="personal-information__content_item">
                        <p class="text-gray-500 font-normal mb-2">Email</p>
                        <p class="text-gray-700 font-medium">{{ userDetails.email }}</p>
                    </div>
                    <div class="personal-information__content_item">
                        <p class="text-gray-500 font-normal mb-2">Université</p>
                        <p class="text-gray-700 font-medium">{{ userDetails.university }}</p>
                    </div>
                    <div class="personal-information__content_item">
                        <p class="text-gray-500 font-normal mb-2">Année</p>
                        <p class="text-gray-700 font-medium">{{ userDetails.year }}</p>
                    </div>
                    <div class="personal-information__content_item">
                        <p class="text-gray-500 font-normal mb-2">Role</p>
                        <p class="text-gray-700 font-medium">{{ userDetails.role }}</p>
                    </div>
                </div>
            </div>
            <div class="subscribe-info p-4 bg-white rounded-md border border-gray">
                <div class="mb-3">
                    <h3 class="text-lg font-medium">Abonnement</h3>
                </div>
                <div class="subscribe-info__content mb-5" v-if="userDetails.subscribes && userDetails.subscribes.length > 0">
                    <div class="subscribe-info__content-item" v-for="item in userDetails.subscribes" :key="item.id">
                        <div>
                            <p class="text-gray-500 font-normal mb-2">ِCode</p>
                            <p class="text-gray-700 font-medium">{{item.Activation_code}}</p>
                        </div>
                        <div>
                            <p class="text-gray-500 font-normal mb-2">Status</p>
                           
                            <el-tag type="success" v-if="item.state == 'active'">{{item.state}}</el-tag>
                            <el-tag type="danger" v-if="item.state == 'expired'">{{item.state}}</el-tag>
                            <!--<el-tag type="success" v-if="item.state == 'active'">{{item.state}}</el-tag>-->
                        </div>
                        <div>
                            <p class="text-gray-500 font-normal mb-2">Date d'activation</p>
                            <p class="text-gray-700 font-medium">{{ item.activation_date }}</p>
                        </div>
                        <div>
                            <p class="text-gray-500 font-normal mb-2">Date d'expiration</p>
                            <p class="text-gray-700 font-medium">{{ item.expiration_date }}</p>
                        </div>
                        <div>
                            <p class="text-gray-500 font-normal mb-2">Année</p>
                            <p class="text-gray-700 font-medium">{{ item.year }}</p>
                        </div>
                        <div v-if="item.state == 'active'">
                            <button class="primary-btn flex p-2 gap-2 items-center sm:w-auto w-full justify-center"
                                @click="showEndSubscriptionModal(item.id)">
                                <OffIcon />
                                <span>Annuler l'abonnement</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <p class="text-gray-500 font-normal mb-2">Pas d'abonnement</p>
                </div>
            </div>

            <UpdateUserModal v-if="showUpdateUserModal" :UpdateDialogFormVisible="showUpdateUserModal"
                @close="showUpdateUserModal = false" :userId="userDetails.id" @refresh="successUpdate"
                :user_data="userData" />
            <EndSubscriptionConfirmation v-if="showEndSubsModal" @close="showEndSubsModal = false, selectedSubscriptionId.value = null" @confirm="EndSubsConfirmation()"/>    
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import EditIcon from '../icons/EditIcon.vue';
import OffIcon from '../icons/OffIcon.vue';
import UserRequests from '../../services/requests/user';
import { useRoute } from 'vue-router';
import SubscribeRequests from '../../services/requests/subscribe';
import UpdateUserModal from './UpdateUserModal.vue';
import EndSubscriptionConfirmation from './EndSubscriptionConfirmation.vue';
export default {
    components: {
        EditIcon,
        OffIcon,
        UpdateUserModal,
        EndSubscriptionConfirmation
    },
    props: {
        linkPath: Function,
    },
    setup(props) {
        const route = useRoute();
        const userDetails = ref({});
        const isLoading = ref(false);
        const showUpdateUserModal = ref(false);
        const isAlert = ref(false);
        const alertTitle = ref('');
        const alertType = ref('');
        const showEndSubsModal = ref(false);
        const selectedSubscriptionId = ref(null);
        const userData = computed(() => {
            let user = {
                id: userDetails.value.id,
                role: userDetails.value.role
            }
            return user;
        })
        async function getUserDetails() {
            let id = route.params.id;
            try {
                const response = await UserRequests.getUserDetails(id);
                if (response) {
                    userDetails.value = response.data.data;
                }
            } catch (error) {

            }
        }

        function EndSubsConfirmation() {
            showEndSubsModal.value = false;
            endSubscription();
        }

        async function endSubscription() {
            try {
                const response = await SubscribeRequests.endSubscription(selectedSubscriptionId.value);
                if (response) {
                    alertTitle.value = "l'abonnement a été annulé avec succée";
                    alertType.value = "success";
                    isAlert.value = true;
                    getUserDetails();
                }
            } catch (error) {

            }
        }

        function successUpdate() {
            alertTitle.value = "l'utilisateur a été modifié avec succée";
            alertType.value = "success";
            isAlert.value = true;
            getUserDetails();
        }

        function showEndSubscriptionModal(id) {
            showEndSubsModal.value = true;
            selectedSubscriptionId.value = id;
        }

        onMounted(() => {
            props.linkPath('students/view/:id');
            getUserDetails();
        })
        return {
            userDetails,
            isLoading,
            endSubscription,
            showUpdateUserModal,
            getUserDetails,
            isAlert,
            successUpdate,
            alertTitle,
            alertType,
            userData,
            showEndSubsModal,
            EndSubsConfirmation,
            selectedSubscriptionId,
            showEndSubscriptionModal
        }
    }
}
</script>

<style scoped>
.student-details {}

.student-details .wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.student-details .wrapper .headline {}

.student-details .wrapper .personal-information {}

.student-details .wrapper .personal-information .personal-information__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 8px;
}

.student-details .wrapper .subscribe-info {}

.student-details .wrapper .subscribe-info .subscribe-info__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.student-details .wrapper .subscribe-info .subscribe-info__content .subscribe-info__content-item {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px 8px;
}
</style>