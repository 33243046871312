import axios from '@/plugins/axios';

const SubscribeRequests = {
    async endSubscription(id) {
        try {
            const response = await axios.post(`/endsubscription/${id}`);
            return response;
        } catch (error) {
            throw error;
        }
    }
};

export default SubscribeRequests;